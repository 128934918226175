export enum FileType {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif',
  PDF = 'application/pdf',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ANY_IMAGE = 'image/*',
}

export interface TUploadFile {
  id: string;
  file: File; // Original File object
  url?: string; // URL for preview and final uploaded file
  progress?: number; // Upload progress
  uploading?: boolean; // Upload status
  error?: string; // Error message if any
}

export interface TFileUploaderProps {
  multiple?: boolean;
  maxFiles?: number;
  maxFileSize?: number;
  onUpload: (file: File, onProgress: (progress: number) => void) => Promise<string>;
  onDelete?: (file: TUploadFile) => Promise<void>;
  onBeforeUpload?: (files: File[]) => boolean | Promise<boolean>;
  initialFiles?: string[];
  hideAfterUpload?: boolean;
  onUploadSuccess?: (files: string[]) => void;
  onUploadError?: (error: string) => void;
  onFileRemove?: (file: TUploadFile) => void;
  CustomPreview?: React.ComponentType<TFilePreviewProps>;
  CustomProgressBar?: React.ComponentType<TProgressBarProps>;
  acceptedFileTypes?: FileType[];
}

export interface TFilePreviewModalProps {
  open: boolean;
  onClose: () => void;
  fileUrl?: string;
  isPDF?: boolean;
}

export interface TFilePreviewProps {
  file: TUploadFile;
  onRemove: () => void;
  CustomProgressBar?: React.ComponentType<TProgressBarProps>;
}

export interface TProgressBarProps {
  progress: number;
  isUploaded: boolean;
}
