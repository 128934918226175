import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Alert, Box, Collapse, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DefaultFilePreview } from './DefaultFilePreview';
import { FileType, TFileUploaderProps, TUploadFile } from './types';
import { createUploadFile, formatFileSize, getFileTypeDisplay, isWildcardType } from './utils';

export const FileUploader: React.FC<TFileUploaderProps> = ({
  multiple = false,
  maxFiles,
  maxFileSize,
  onUpload,
  onDelete,
  onBeforeUpload,
  initialFiles = [],
  hideAfterUpload = false,
  onUploadSuccess,
  onUploadError,
  onFileRemove,
  CustomPreview = DefaultFilePreview,
  CustomProgressBar,
  acceptedFileTypes = [FileType.ANY_IMAGE, FileType.PDF],
}) => {
  const [files, setFiles] = useState<TUploadFile[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const loadInitialFiles = async () => {
      const initialFileObjects = await Promise.all(
        initialFiles.map(async (url) => {
          const response = await fetch(url);
          const blob = await response.blob();
          const file = new File([blob], url.split('/').pop() || 'file', {
            type: blob.type,
          });
          const uploadFile = await createUploadFile(file);
          return {
            ...uploadFile,
            progress: 100,
            uploading: false,
            url: url,
          } as TUploadFile;
        }),
      );
      setFiles(initialFileObjects);
    };

    if (initialFiles.length > 0) {
      loadInitialFiles();
    }
  }, [initialFiles]);

  // Clear error message after 5 seconds
  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const handleError = (message: string) => {
    setErrorMessage(message);
    onUploadError?.(message);
  };

  const isUploadDisabled = useMemo(() => {
    const effectiveMaxFiles = multiple ? maxFiles : 1;
    return effectiveMaxFiles ? files.length >= effectiveMaxFiles : false;
  }, [multiple, maxFiles, files.length]);

  const validateFiles = (files: File[]): { valid: File[]; invalid: File[] } => {
    return files.reduce(
      (acc, file) => {
        // Check file type
        const isValidType = isFileTypeAccepted(file, acceptedFileTypes);
        // Check file size if maxFileSize is set
        const isValidSize = maxFileSize ? file.size <= maxFileSize : true;

        if (!isValidType || !isValidSize) {
          acc.invalid.push(file);
        } else {
          acc.valid.push(file);
        }
        return acc;
      },
      { valid: [] as File[], invalid: [] as File[] },
    );
  };

  const handleFilesSelection = async (selectedFiles: File[]) => {
    if (!selectedFiles.length) return;

    // For single file upload, only take the first file
    const filesToProcess = multiple ? selectedFiles : [selectedFiles[0]];

    // If not multiple and trying to upload multiple files, show warning
    if (!multiple && selectedFiles.length > 1) {
      handleError(
        `Only single file upload is allowed. Selected first file: ${selectedFiles[0].name}. ` +
          `Skipped ${selectedFiles.length - 1} file${selectedFiles.length > 2 ? 's' : ''}.`,
      );
    }

    // Validate both file types and sizes
    const { valid, invalid } = validateFiles(filesToProcess);

    if (invalid.length > 0) {
      const invalidFileNames = invalid
        .map((file) => {
          const sizeIssue = maxFileSize && file.size > maxFileSize;
          const issue = sizeIssue
            ? `exceeds size limit (${formatFileSize(file.size)})`
            : 'invalid type';
          return `${file.name} (${issue})`;
        })
        .join(', ');

      if (valid.length === 0) {
        handleError(
          `Invalid file${invalid.length > 1 ? 's' : ''}: ${invalidFileNames}. ` +
            `Accepted types: ${acceptedFileTypes.map(getFileTypeDisplay).join(', ')}` +
            (maxFileSize ? ` • Max size: ${formatFileSize(maxFileSize)}` : ''),
        );
        return;
      } else {
        handleError(`Some files will be skipped: ${invalidFileNames}`);
      }
    }

    if (valid.length === 0) return;

    const effectiveMaxFiles = multiple ? maxFiles : 1;
    const totalFiles = files.length + valid.length;
    const remainingSlots = effectiveMaxFiles ? effectiveMaxFiles - files.length : valid.length;

    // Determine which files to upload based on limits
    const filesToUpload =
      effectiveMaxFiles && totalFiles > effectiveMaxFiles ? valid.slice(0, remainingSlots) : valid;

    // Show warning if some files will be skipped due to limits
    if (effectiveMaxFiles && totalFiles > effectiveMaxFiles) {
      const remainingFiles = valid.slice(remainingSlots);
      handleError(
        `Uploading first ${remainingSlots} file${remainingSlots === 1 ? '' : 's'}. ` +
          `Skipping ${remainingFiles.length} file${remainingFiles.length === 1 ? '' : 's'} ` +
          `due to ${multiple ? effectiveMaxFiles : 'single file'} limit.`,
      );
    }

    // Validate with onBeforeUpload if provided
    if (onBeforeUpload) {
      const isValid = await onBeforeUpload(filesToUpload);
      if (!isValid) return;
    }

    // Create upload file objects
    const newFiles = await Promise.all(filesToUpload.map((file) => createUploadFile(file)));

    // Handle file replacement for single file upload
    if (!multiple) {
      // Remove existing file if any
      if (files.length > 0) {
        const existingFile = files[0];
        if (onDelete) {
          await onDelete(existingFile);
        }
      }
      setFiles(newFiles);
    } else {
      setFiles((prev) => [...prev, ...newFiles]);
    }

    // Upload the files
    await uploadFiles(newFiles);

    // Clear the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const isFileTypeAccepted = (file: File, acceptedTypes: FileType[]): boolean => {
    return acceptedTypes.some((acceptedType) => {
      // Handle wildcard types (e.g., 'image/*')
      if (isWildcardType(acceptedType)) {
        const mainType = acceptedType.split('/')[0];
        return file.type.startsWith(mainType);
      }
      return file.type === acceptedType;
    });
  };

  const handleDrop = useCallback(
    async (e: React.DragEvent) => {
      e.preventDefault();
      setIsDragging(false);

      const droppedFiles = Array.from(e.dataTransfer.files);
      await handleFilesSelection(droppedFiles);
    },
    [files.length, multiple, maxFiles, acceptedFileTypes, onBeforeUpload],
  );

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || []);
    await handleFilesSelection(selectedFiles);
  };

  const uploadFiles = async (filesToUpload: TUploadFile[]) => {
    const uploadPromises = filesToUpload.map(async (file) => {
      try {
        const handleProgress = (progress: number) => {
          setFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            const fileIndex = newFiles.findIndex((f) => f.id === file.id);
            if (fileIndex !== -1) {
              newFiles[fileIndex] = {
                ...newFiles[fileIndex],
                progress,
                uploading: progress < 100,
              } as TUploadFile;
            }
            return newFiles;
          });
        };

        const url = await onUpload(file.file, handleProgress);

        setFiles((prevFiles) => {
          const newFiles = [...prevFiles];
          const fileIndex = newFiles.findIndex((f) => f.id === file.id);
          if (fileIndex !== -1) {
            newFiles[fileIndex] = {
              ...newFiles[fileIndex],
              progress: 100,
              uploading: false,
              url,
            } as TUploadFile;
          }
          return newFiles;
        });

        return url;
      } catch (error) {
        setFiles((prevFiles) => {
          const newFiles = [...prevFiles];
          const fileIndex = newFiles.findIndex((f) => f.id === file.id);
          if (fileIndex !== -1) {
            newFiles[fileIndex] = {
              ...newFiles[fileIndex],
              error: 'Upload failed',
              uploading: false,
              progress: 0,
            } as TUploadFile;
          }
          return newFiles;
        });
        onUploadError?.('File upload failed');
        return null;
      }
    });

    const uploadedUrls = (await Promise.all(uploadPromises)).filter(Boolean);
    onUploadSuccess?.(uploadedUrls);
  };

  const handleFileRemove = async (file: TUploadFile) => {
    try {
      if (onDelete) {
        await onDelete(file);
        onFileRemove?.(file);
      }
      setFiles((prev) => prev.filter((f) => f.id !== file.id));
    } catch (error) {
      onUploadError?.('File removal failed');
    }
  };

  if (hideAfterUpload && files.length > 0 && files.every((file) => !file.uploading)) {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: tokens.spacingBase }}>
        {files.map((file) => (
          <CustomPreview
            key={file.id}
            file={file}
            onRemove={() => handleFileRemove(file)}
            CustomProgressBar={CustomProgressBar}
          />
        ))}
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={Boolean(errorMessage)}>
        <Alert
          severity="error"
          sx={{ mb: tokens.spacingBase }}
          onClose={() => setErrorMessage(null)}
        >
          {errorMessage}
        </Alert>
      </Collapse>

      <Box
        sx={{
          borderStyle: 'dashed',
          borderWidth: tokens.spacing3xs,
          borderColor: isDragging ? 'primary.main' : 'divider',
          borderRadius: tokens.borderRadiusSm,
          p: tokens.spacingBase,
          position: 'relative',
        }}
      >
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileSelect}
          multiple={multiple}
          accept={acceptedFileTypes.join(',')}
        />

        <Box
          onDrop={handleDrop}
          onDragOver={(e) => {
            e.preventDefault();
            !isUploadDisabled && setIsDragging(true);
          }}
          onDragLeave={() => setIsDragging(false)}
          onClick={() => !isUploadDisabled && fileInputRef.current?.click()}
          sx={{
            p: 3,
            textAlign: 'center',
            bgcolor: isDragging ? 'action.hover' : 'background.paper',
            cursor: isUploadDisabled ? 'not-allowed' : 'pointer',
            opacity: isUploadDisabled ? 0.6 : 1,
            pointerEvents: isUploadDisabled ? 'none' : 'auto',
          }}
        >
          <CloudUploadIcon
            sx={{
              fontSize: tokens.fontSize7xl,
              color: isUploadDisabled ? 'grey.500' : 'primary.main',
              mb: tokens.spacingXs,
            }}
          />
          <Typography color={isUploadDisabled ? 'text.disabled' : 'text.primary'}>
            {isUploadDisabled
              ? maxFiles
                ? `Maximum ${maxFiles} files reached. Remove files to upload more.`
                : 'Maximum files reached'
              : 'Drag and drop files here or click to select files'}
          </Typography>
          {maxFiles && (
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ display: 'block', mt: tokens.spacing2xs }}
            >
              {`${files.length} of ${maxFiles} files uploaded`}
            </Typography>
          )}
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ display: 'block', mt: tokens.spacing2xs }}
          >
            Accepted types: {acceptedFileTypes.map(getFileTypeDisplay).join(', ')}
            {maxFileSize && ` • Max size: ${formatFileSize(maxFileSize)}`}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: tokens.spacingBase,
            mt: tokens.spacingBase,
          }}
        >
          {files.map((file) => (
            <CustomPreview
              key={file.id}
              file={file}
              onRemove={() => handleFileRemove(file)}
              CustomProgressBar={CustomProgressBar}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
